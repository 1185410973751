import saasApp from "../../routes/app.js";

<360-saas-main>
  <button on-click("goToCategoriesPage")>Categories</button>
  <button on-click("goToProducstPage")>Products</button>
  <button on-click("goToTemplatesPage")>Templates</button>
  <button on-click("goToSwatchesPage")>Swatches</button>
  <button on-click("goToModelsPage")>Models</button>
  <button on-click("goToTexturesPage")>Textures</button>
  <button on-click("goToMaterials")>Materials</button>
</360-saas-main>

class {
    goToCategoriesPage() {
        saasApp.goToCategoriesPage();
    }   

    goToProducstPage() {
        saasApp.goToProductsPage();
    }   

    goToTemplatesPage() {
        window.location.href = "/templates";
    }   

    goToSwatchesPage() {
        window.location.href = "/swatches";
    }

    goToModelsPage() {
        window.location.href = "/models";
    }

    goToTexturesPage() {
        window.location.href = "/textures";
    }

    goToMaterials() {
        window.location.href = "/materials";
    }
}